/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "BentonSansComp";
  font-display: swap;
  src: url("../fonts/BentonSansExtraCompBold.eot"); /* IE9 Compat Modes */
  src: url("../fonts/BentonSansExtraCompBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/BentonSansExtraCompBold.otf") format("opentype"), /* Open Type Font */
  url("../fonts/BentonSansExtraCompBold.svg") format("svg"), /* Legacy iOS */
  url("../fonts/BentonSansExtraCompBold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../fonts/BentonSansExtraCompBold.woff") format("woff"), /* Modern Browsers */
  url("../fonts/BentonSansExtraCompBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "BentonSansComp";
  font-display: swap;
  src: url("../fonts/BentonSansCompLight.eot"); /* IE9 Compat Modes */
  src: url("../fonts/BentonSansCompLight.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/BentonSansCompLight.otf") format("opentype"), /* Open Type Font */
  url("../fonts/BentonSansCompLight.svg") format("svg"), /* Legacy iOS */
  url("../fonts/BentonSansCompLight.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../fonts/BentonSansCompLight.woff") format("woff"), /* Modern Browsers */
  url("../fonts/BentonSansCompLight.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v23-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v23-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v23-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v23-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/lato-v23-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v23-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v23-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v23-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}