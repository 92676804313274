.bildmittext {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .bildmittext-inner {
    position: relative;
    width: calc(100% / 2 - 20px);
    margin: 0px 10px;
    .bildmittext-box {
      picture {
        img {
          max-width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}