.parallax-gallery {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
  height: 730px !important;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  .parallax-gallery-inner  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .jarallax-img {
      will-change: transform;
    }
    .active {
      opacity: 1 !important;
    }
  }
  .jarallax {
    position: relative;
    z-index: 0;
  }
  .jarallax .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    //font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
      min-width: 100%;
      min-height: 100%;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
    }
  }
}