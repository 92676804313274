$primary-color: #004750;
$primary-color-light: #007178;
$primary-color-dark: #004750 !default;
$secondary-color: #157178;
$secondary-color-dark: #004750 !default;

$header--height: 100px;
$header--bg-color: #ddc8a2;
$nav--bg-color: #004750;
$font-family-base: 'Lato';
$font-weight-base: 300;
$headings-font-weight: 400;

$logocloud-icon--color: #157178;


$kontakt-icons--a-color: $header--bg-color;

@import "modules/header.scss";
@import "modules/kontakt_icons";
@import "../../tinymce.css";
@import "modules/logocloud";
@import "modules/teaser-halb";
@import "modules/parallax-gallery";
@import "modules/bildmittext";

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1500px
);

body {
  //text-rendering: geometricPrecision !important;
  font-size: 1.1rem !important;
  color: #333333 !important;
  h1 {
    font-family: "BentonSansComp";
    margin-top: 0px !important;
    font-size: 3rem !important;
    text-transform: uppercase;
    font-weight: 400 !important;
    float: left;
    margin-right: 20px !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-weight: 900 !important;
    font-size: 1.1rem !important;
    text-align: left !important;
    position: relative;
    padding-top: 23px !important;
    margin-bottom: 14px;
  }
  .bento {
    font-family: "BentonSansComp" !important;
  }
  header {
    position: fixed !important;
    z-index: 999;
    nav {
      margin-top: 20px;
      font-size: 30px;
      font-family: $font-family-base;
      color: #117179;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: row;
      }
      .right {
        margin-right: 20px;
        i {
          svg, img {
            height: 50px;
            margin-top: 13px;
            color: $header--bg-color;
          }
        }
      }
      i {
        svg, img {
          height: 70px;
          width: 120px;
          margin-top: 3px;
        }
      }
      .company-name {
        position: relative;
        height: 35px;
        margin-top: 20px;
        font-weight: bold;
        font-family: "BentonSansComp";
      }
      .nav-icons {

      }
    }
  }
  main {
    padding-top: 70px;

    .grid-container {
      will-change: transform;
      overflow: hidden;
    }
  }

  .container-color-1:before,
  .container-color-2:before,
  .container-color-3:before,
  .container-color-4:before,
  .container-color-5:before,
  .container-color-6:before {
    font-weight: bold;
    font-size: 300px;
    line-height: 286px;
    margin-left: -10px;
    position: absolute;
  }
  .container-color-1, .container-color-2, .container-color-3, .container-color-5, .container-color-6 {
    min-height: 300px;
    position: relative;
    display: flex;
    >div {
      position: relative;
      margin-top: 125px;
    }
  }

  .container-color-1 {
    background: #ffffff;
    h1 {
      color: $secondary-color !important;
    }
  }
  .container-color-1:before, .container-color-2:before, .container-color-3:before, .container-color-5:before, .container-color-6:before {
    font-family: 'BentonSansComp' !important;
  }
  .container-color-1:before {
    content: "DAS";
    color: #f2f2f1;
  }

  .container-color-5:before {
    content: "CO80";
    color: #f2f2f1;

  }

  .container-color-6:before {
    content: "§";
    color: #f2f2f1;
  }

  .container-color-6 {
    padding-top: 50px;
    background: #ffffff;
  }

  .container-color-5 {
    background: #ffffff;
    h1 {
      color: $secondary-color !important;
    }
  }

  .container-color-2 {
    background: #d7c8a3;
    p, span, h2 {
      mix-blend-mode: multiply;
    }
  }
  .container-color-2:before {
    content: "WEM";
    color: #cbbc97;
  }

  .container-color-3 {
    background: #004750;
    color: #ffffff;
    .uppercase {
      color: $header--bg-color !important;
    }
    h1 {
      color: $header--bg-color !important;
    }
    h2 {
      color: #ffffff !important;
    }
  }
  .container-color-3:before {
    content: "HEY";
    color: #157178;
    line-height: 282px;
  }

  .tinyMCE {
    p {
      line-height: 20px;
    }
  }

  .header--text {
    left: 200px !important;
    top: 80px !important;
    transform: none !important;
    color: $header--bg-color;
    font-family: "BentonSansComp";
  }
  footer {
    background: #ffffff !important;
    a {
      color: #666666 !important;
      transition: opacity 0.3s;
    }
    a:hover {
      color: #666666 !important;
      opacity: 0.7;
    }
    .footer-hr-one, .footer-hr-two {
      position: absolute;
      width: calc(50% - 70px);
      top: 123px;
    }
    .footer-hr-one {
      width: calc(50% - 25px);
    }
    .footer-hr-two {
      right: 0px !important;
      left: unset !important;
    }
    h1 {
      text-align: center;
      color: #666666;
      width: 100%;
    }
  }
}