@include media-breakpoint-down(lg) {
  header {
    nav {
      justify-content: flex-start !important;
      i {
        svg {
          height: 60px !important;
          margin-top: 10px !important;
        }
      }
      .left {
        width: 100% !important;
      }
      .right {
        position: absolute;
        right: 0px;
        top: 5px;
        width: 110px !important;
        margin-right: 0px !important;
        .nav-icons {
          img,svg {
            height: unset !important;
            width: 30px !important;
            margin-right: 20px !important;
          }
        }
      }
    }
  }
  .header {
    .header--text {
      font-size: 25px;
      left: 50px !important;
      top: 50px !important;
    }
    picture {
      img {
        width: calc(100% - 20px) !important;
        margin: 90px 20px 50px 0px !important;
      }
    }
  }
  body {
    h2 {
      padding-top: 2px !important;
    }

    .logocloud {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    .parallax-gallery {
      height: 300px !important;
      width: 100%;
      margin-left: 0px;
      img {
        min-height: unset !important;
        max-width: 100% !important;
      }
    }

    .tinyMCE {
      margin-bottom: 50px;
    }
  }
}