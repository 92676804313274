$logocloud-icon--color: #ff0000 !default;

.logocloud {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .fontawsome-icon {
    width: 100px;
    height: 100px;
  }
  img, svg {
    min-width: 100px;
    max-width: 100px;
    * {
      fill: $logocloud-icon--color !important;
    }
  }
}